import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import ProductWithImage from "../../../components/ProductWithImage";

import fullfillmentBooking from "../../images/fulfillment-booking.png";
import fullfillmentBooking2 from "../../images/fulfillment-booking@2x.png";
import fullfillmentBooking3 from "../../images/fulfillment-booking@3x.png";

import logisticsPlanning from "../../images/logistics-planning.png";
import logisticsPlanning2 from "../../images/logistics-planning@2x.png";
import logisticsPlanning3 from "../../images/logistics-planning@3x.png";

import logisticsDelivery from "../../images/logistics-delivery.png";
import logisticsDelivery2 from "../../images/logistics-delivery@2x.png";
import logisticsDelivery3 from "../../images/logistics-delivery@3x.png";

import logisticsTracking from "../../images/logistics-tracking.png";
import logisticsTracking2 from "../../images/logistics-tracking@2x.png";
import logisticsTracking3 from "../../images/logistics-tracking@3x.png";

import "./style.css";
import ListView from "../../../components/ListView";
import { Message } from "../../../lib/getMessage";

const bookingDesc = (isDirectionRTL) => (
  <ListView
    heading={<Message dictKey="products.logistics.booking.description" />}
    lists={[
      <Message dictKey="products.logistics.booking.item1" />,
      <Message dictKey="products.logistics.booking.item2" />,
      <Message dictKey="products.logistics.booking.item3" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const planningDesc = (isDirectionRTL) => (
  <ListView
    heading={<Message dictKey="products.logistics.planning.description" />}
    lists={[
      <Message dictKey="products.logistics.planning.item1" />,
      <Message dictKey="products.logistics.planning.item2" />,
      <Message dictKey="products.logistics.planning.item3" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const deliveryDesc = (isDirectionRTL) => (
  <ListView
    heading={<Message dictKey="products.logistics.delivery.description" />}
    lists={[
      <Message dictKey="products.logistics.delivery.item1" />,
      <Message dictKey="products.logistics.delivery.item2" />,
      <Message dictKey="products.logistics.delivery.item3" />,
      <Message dictKey="products.logistics.delivery.item4" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const trackingDesc = (isDirectionRTL) => (
  <ListView
    heading={<Message dictKey="products.logistics.tracking.description" />}
    lists={[
      <Message dictKey="products.logistics.tracking.item1" />,
      <Message dictKey="products.logistics.tracking.item2" />,
    ]}
    isDirectionRTL={isDirectionRTL}
  />
);

const Logistics = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-logistics">
          <HeroBanner
            title={<Message dictKey="products.logistics.title" />}
            className={`logistics-banner ${
              isDirectionRTL && "logistics-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />

          <section className="pr-logistics-rprt">
            <ProductWithImage
              name="booking"
              className="booking"
              description={[
                {
                  type: "component",
                  val: bookingDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: fullfillmentBooking,
                xx: fullfillmentBooking2,
                xxx: fullfillmentBooking3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="planning"
              className="planning"
              description={[
                {
                  type: "component",
                  val: planningDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsPlanning,
                xx: logisticsPlanning2,
                xxx: logisticsPlanning3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="delivery-optimization"
              className="delivery-optimization"
              description={[
                {
                  type: "component",
                  val: deliveryDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsDelivery,
                xx: logisticsDelivery2,
                xxx: logisticsDelivery3,
              }}
              isDirectionRTL={isDirectionRTL}
            />

            <ProductWithImage
              name="tracking"
              className="tracking"
              description={[
                {
                  type: "component",
                  val: trackingDesc(isDirectionRTL),
                  id: 1,
                },
              ]}
              image={{
                normal: logisticsTracking,
                xx: logisticsTracking2,
                xxx: logisticsTracking3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(Logistics);
